import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/foundation/design-tokens/spacing",
  "title": "Spacing tokens - Usage",
  "navTitle": "Spacing"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Spacing of elements can be used to create visual hierarchy for content and guide focus to certain elements. Too dense information can be hard to digest for a user, so make sure to leave enough space in the user interfaces.`}</p>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul">{`Use small spacing to group elements together and larger spacing separate them. Elements that are close together appear to be more related than things that are spaced further apart.`}</li>
      <li parentName="ul">{`Use spacing to highlight important elements. Elements with more spacing around them tend to perceive higher importance than elements that have less space around them.`}</li>
      <li parentName="ul">{`Use spacing in a way that fits the function and content of the service. Ample spacing is more fitting for providing mood in branding and communication, denser spacing for performing information heavy tasks efficiently.`}</li>
    </ul>
    <h3 {...{
      "id": "using-spacing-tokens",
      "style": {
        "position": "relative"
      }
    }}>{`Using spacing tokens`}<a parentName="h3" {...{
        "href": "#using-spacing-tokens",
        "aria-label": "using spacing tokens permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The spacing tokens are based on the HDS typographic scale. They are divided in two scales for different uses:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Spacing tokens`}</strong>{` are used for component-level spacing, and allow a more detailed fine tuning.`}</li>
      <li parentName="ul"><strong parentName="li">{`Layout spacing tokens`}</strong>{` are used for page layouts. They help keeping pages consistent and ensure that you are not making layouts too dense.`}</li>
    </ul>
    <p>{`Token values can be multiplied and divided using even numbers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      